<template>
  <sign-page
    title-text="表配置"
    :request="request"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    :form-parms-update="formParmsUpdate"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  confTableRequest as request
} from '@/api'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '表名',
          key: 'tableName'
        },
        {
          type: 'input',
          label: '所属库',
          key: 'tableSchema'
        },
        {
          type: 'input',
          label: '名称',
          key: 'label'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          data.push({
            field: item.key,
            title: item.label
          })
        })
        return data
      }
    }
  },
  methods: {
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
